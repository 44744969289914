import React from "react";
import ProductSmall from "./ProductSmall";

class CategoryListing extends React.Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12 collection-name">
            {this.props.category.namePlural}
          </div>
        </div>
        <div className="row">
          {this.props.category.products.map(i => (
            <ProductSmall product={i} key={i.code} />
          ))}
        </div>
      </div>
    );
  }
}

export default CategoryListing;
