import React from "react";
import "./App.css";
import Header from "./Header";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import CategoriesNav from "./CategoriesNav";
import CategoryListing from "./CategoryListing";
import CollectionListing from "./CollectionListing";
import Product from "./Product";
import agent from "../agent";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      categoriesLoaded: false,
      collectionsLoaded: false,
      collections: [],
      categories: []
    };
  }

  componentDidMount() {
    agent.Collections.all().then(
      result => {
        this.setState({
          collectionsLoaded: true,
          collections: result || []
        });
      },
      error => {
        this.setState({
          collectionsLoaded: true,
          error
        });
      }
    );

    agent.Categories.all().then(
      result => {
        this.setState({
          categoriesLoaded: true,
          categories: result || []
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      error => {
        this.setState({
          categoriesLoaded: true,
          error
        });
      }
    );
  }

  render() {
    const {
      error,
      collectionsLoaded,
      categoriesLoaded,
      categories,
      collections
    } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!categoriesLoaded || !collectionsLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className="App">
          <div className="container">
            <Header appName="Magnifica" />
            <CategoriesNav categories={categories} />
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Home collections={collections} />}
              />
              {categories.map(c => (
                <Route
                  path={c.path}
                  component={() => <CategoryListing category={c} />}
                  key={c.id}
                />
              ))}
              {collections.map(c => (
                <Route
                  path={c.path}
                  component={() => <CollectionListing collection={c} />}
                  key={c.id}
                />
              ))}
              <Route path="/:code" component={Product} />

              {/* <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/editor/:slug" component={Editor} />
        <Route path="/editor" component={Editor} />
        <Route path="/article/:id" component={Article} />
        <Route path="/settings" component={Settings} />
        <Route path="/@:username/favorites" component={ProfileFavorites} />
        <Route path="/@:username" component={Profile} /> */}
            </Switch>
          </div>
        </div>
      );
    }
  }
}

export default App;
