import React from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends React.Component {
  render() {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={this.props.product.category.path}>
              {this.props.product.category.namePlural}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {this.props.product.title}
          </li>
        </ol>
      </nav>
    );
  }
}

export default Breadcrumb;
