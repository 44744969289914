import React from "react";
import Breadcrumb from "./Breadcrumb";
import StoreButton from "./StoreButton";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      productLoaded: false,
      product: null,
      category: null,
      categoryLoaded: false,
      selectedImageIndex: 0,
      selectedImage: null,
    };
  }

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/products/${this.props.match.params.code}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            productLoaded: true,
            product: result,
            selectedImage: result.images[0],
          });
        },
        (error) => {
          this.setState({
            productLoaded: true,
            error,
          });
        }
      );
  }

  selectImage(index) {
    this.setState({
      selectedImageIndex: index,
      selectedImage: this.state.product.images[index],
    });
  }

  render() {
    const {
      error,
      productLoaded,
      product,
      selectedImage,
      selectedImageIndex,
    } = this.state;

    const fontFamilyStyle = {
      fontFamily: "Karla,Arial,Helvetica,sans-serif",
    };

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!productLoaded) {
      return <div>Loading...</div>;
    } else {
      var oldPrice = product.discountedPrice ? (
        <span className="discount">
          {new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: "USD",
          }).format(product.regularPrice)}
        </span>
      ) : null;

      return (
        <div>
          <div className="row">
            <div className="col-12">
              <Breadcrumb product={product} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="product-thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/" + selectedImage}
                  alt={product.title}
                />
              </div>
              <div>
                {product.images.map((i, index) => (
                  <div
                    className={`product-thumbnail-sml ${
                      index === selectedImageIndex ? "active" : null
                    }`}
                    key={i}
                    onClick={() => this.selectImage(index)}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/40/" + i}
                      alt={i}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-6" style={fontFamilyStyle}>
              <div className="product-title">{product.title}</div>
              <div className="product-price">
                {oldPrice}
                {new Intl.NumberFormat("us-EN", {
                  style: "currency",
                  currency: "USD",
                }).format(product.finalPrice)}
              </div>
              <div className="product-code">SKU: {product.code}</div>
              <div className="product-description">
                <pre>{product.description}</pre>
              </div>
              <StoreButton url={product.facebookUrl} store="facebook" />
              <StoreButton url={product.ebayUrl} store="ebay" />
              <StoreButton url={product.instagramUrl} store="instagram" />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Product;
