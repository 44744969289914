import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="app-header align-center">
            <Link to="/" className="app-name">
              {this.props.appName}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
