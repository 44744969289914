import React from "react";
import { Link } from "react-router-dom";

class ProductSmall extends React.Component {
  render() {
    var oldPrice = this.props.product.discountedPrice ? (
      <span className="discount">
        {new Intl.NumberFormat("us-EN", {
          style: "currency",
          currency: "USD"
        }).format(this.props.product.regularPrice)}
      </span>
    ) : null;

    return (
      <div className="col-sm-12 col-md-6 col-lg-3 product-small">
        <Link to={"/" + this.props.product.code} className="product-small-link">
          {process.env.PUBLIC_URL}
          <div className="product-small-thumbnail">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/260/" +
                this.props.product.images[0]
              }
              alt={this.props.product.code}
            />
          </div>
          <div className="product-small-title">{this.props.product.title}</div>
          <div className="product-small-price">
            {oldPrice}
            {new Intl.NumberFormat("us-EN", {
              style: "currency",
              currency: "USD"
            }).format(this.props.product.finalPrice)}
          </div>
        </Link>
      </div>
    );
  }
}

export default ProductSmall;
