import React from "react";
import { Link } from "react-router-dom";

class CategoriesNav extends React.Component {
  categories = [
    { name: "Rings", path: "rings" },
    { name: "Necklaces", path: "necklaces" }
  ];

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <ul className="nav justify-content-center">
            {this.props.categories.map(c => (
              <li className="nav-item" key={c.id}>
                <Link to={c.path} className="nav-link category-nav-link">
                  {c.namePlural}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default CategoriesNav;
