import React from "react";
import CollectionPreview from "./CollectionPreview";

class Home extends React.Component {
  render() {
    return this.props.collections.map(c => (
      <CollectionPreview collection={c} />
    ));
  }
}

export default Home;
