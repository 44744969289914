import React from "react";
import ProductSmall from "./ProductSmall";

class CollectionListing extends React.Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12 collection-name">
            {this.props.collection.name}
          </div>
        </div>
        <div className="row">
          {this.props.collection.products.map(i => (
            <ProductSmall product={i} />
          ))}
        </div>
      </div>
    );
  }
}

export default CollectionListing;
