import React from "react";

class StoreButton extends React.Component {
  render() {
    const { url, store } = this.props;

    if (url) {
      return (
        <div className="product-store">
          <a
            className={`btn btn-${store}`}
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            View in {store}
          </a>
        </div>
      );
    }

    return null;
  }
}

export default StoreButton;
