import React from "react";
import ProductSmall from "./ProductSmall";
import { Link } from "react-router-dom";

class CollectionPreview extends React.Component {
  render() {
    var items = this.props.collection.products;
    if (items.length > 4) {
      items = items.slice(0, 4);
    }

    return (
      <div>
        <div className="row">
          <div className="col-12 collection-name">
            <Link to={this.props.collection.path}>
              {this.props.collection.name}
            </Link>
          </div>
        </div>
        <div className="row">
          {items.map(i => (
            <ProductSmall product={i} />
          ))}
        </div>
      </div>
    );
  }
}

export default CollectionPreview;
